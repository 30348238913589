exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labels-covid-tracking-js": () => import("./../../../src/pages/labels/covid-tracking.js" /* webpackChunkName: "component---src-pages-labels-covid-tracking-js" */),
  "component---src-pages-labels-index-js": () => import("./../../../src/pages/labels/index.js" /* webpackChunkName: "component---src-pages-labels-index-js" */),
  "component---src-pages-labels-isic-2018-js": () => import("./../../../src/pages/labels/isic-2018.js" /* webpackChunkName: "component---src-pages-labels-isic-2018-js" */),
  "component---src-pages-labels-isic-2020-js": () => import("./../../../src/pages/labels/isic-2020.js" /* webpackChunkName: "component---src-pages-labels-isic-2020-js" */),
  "component---src-pages-labels-nopv-nyc-js": () => import("./../../../src/pages/labels/nopv-nyc.js" /* webpackChunkName: "component---src-pages-labels-nopv-nyc-js" */),
  "component---src-pages-labels-taxbills-nyc-js": () => import("./../../../src/pages/labels/taxbills-nyc.js" /* webpackChunkName: "component---src-pages-labels-taxbills-nyc-js" */),
  "component---src-pages-labels-v-3-js": () => import("./../../../src/pages/labels/v3.js" /* webpackChunkName: "component---src-pages-labels-v-3-js" */)
}

